import GlobalLayout from "components/page/GlobalLayout";
import TeamsPageTemplate from "components/teams/TeamsPageTemplate";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function OpsTeamPage() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "OPERATIONS & FACILITIES",
        title: "Streamline and Centralize Your Security Operations",
        description:
          "Modernize and scale your security operations with a cloud-based platform that streamlines your tech stack—manage cameras, access control, alarms, sensors, and integrations on a single pane of glass.",
        image: data.headerImage,
        button1: { text: "Request Demo", path: null },
      },
      logos: [
        {
          img: data.logo1,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo2,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Construction",
        },
        {
          img: data.logo4,
          width: "186px ",
          alt: "Los Angeles Film School",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo3,
          width: "115px",
          alt: "Stryker",
        },
      ],
      benefitRows: [
        {
          preTitle: "BOOST EFFICIENCY",
          title: "Unify Management to Streamline Security Operations ",
          description:
            "Security management has never been more seamless. With native remote access on an all-in-one cloud platform, it’s easy to manage unlimited users, devices, locations, and integrations at scale.  ",
          specs: [
            { title: "18x", description: "faster incident resolution" },
            {
              title: "99.9%",
              description: "uptime for unlimited devices and locations",
            },
          ],
          image: data.benefitImage1,
        },
        {
          preTitle: "OPTIMIZE PROCESSES",
          title: "Boost ROI with Smarter Security Operations ",
          description:
            "Save time and resources with user-friendly tools, lightning-quick analytics, and automatic firmware updates that keep your tech up to date with no manual work. Leverage Rhombus’ suite of AI features to speed up investigation and take informed action with confidence.",
          specs: [
            { title: "150%", description: "productivity increase" },
            {
              title: "40%",
              description: "lower costs versus legacy systems",
            },
          ],
          image: data.benefitImage2,
        },
        {
          preTitle: "LONG-TERM PARTNERSHIP",
          title: "Partner With a Team That Cares",
          description:
            "Experience best-in-class partnership and support throughout your entire lifetime with Rhombus. Work with a dedicated point of contact and in-house team that provides hands-on support, from end-user training to building custom solutions from scratch.",
          specs: [
            { title: "99%", description: "renewal rate, industry record" },
            {
              title: "24/7",
              description: "in-house support with automatic ticket creation",
            },
          ],
          image: data.benefitImage3,
        },
      ],
      infoSlider: {
        title:
          "Feature-Rich Security Platform for Operations & Facilities Teams ",
        slides: [
          {
            tabTitle: "Smart Search",
            preTitle: "FASTER INVESTIGATIONS",
            title: "Smart Search",
            description:
              "Accelerate investigations by pinpointing footage based on key details. Search using AI analytics and find activity based on human movement, facial recognition, license plate recognition, audio, color search, and more. ",
            image: data.infoSlide1,
          },
          {
            tabTitle: "Easy Sharing",
            preTitle: "COMMUNICATE & COLLABORATE",
            title: "Easy Sharing",
            description:
              "Collaboration is easy, fast, and flexible. Securely share video clips, livestreams, and historical streams with anyone—send footage via text, email, and URL and view on any device with no login required. ",
            image: data.infoSlide2,
          },
          {
            tabTitle: "User Roles",
            preTitle: "MANAGE END-USERS",
            title: "User Roles",
            description:
              "Seamlessly add unlimited locations, users, and devices with no tiers or seat limits. Gain granular control over user permissions with custom roles and access types that make it easy to scale geographically and vertically within your organization. ",
            image: data.infoSlide3,
          },
          {
            tabTitle: "In-Depth Reporting",
            preTitle: "DATA-DRIVEN INSIGHTS",
            title: "In-Depth Reporting",
            description:
              "Get comprehensive visibility into system performance and operations with diagnostic logs, audit logs, bandwidth reports, device inventory, device up/down time, and more. ",
            image: data.infoSlide4,
          },
          {
            tabTitle: "Open & Integrated",
            preTitle: "YOUR SECURITY, YOUR WAY",
            title: "Open & Integrated",
            description:
              "Leverage your existing technologies with plug-and-play integrations for your favorite third-party solutions. Plus, enjoy the freedom to customize your system to your organization’s unique needs—use Rhombus’ open API to build custom functionality. ",
            image: data.infoSlide5,
          },
        ],
      },
      customerReviews: [
        {
          image: data.review1,
          author: {
            avatar: data.avatar1,
            name: "Jon Jenkin",
            role: "Senior Facilities Manager at SRE Property Management",
          },
        },

        {
          image: data.review2,
          author: {
            avatar: data.avatar2,
            name: "Michael Starkman",
            role: "Operations at Keep It Simple Storage",
          },
        },
        {
          image: data.review3,
          author: {
            avatar: data.avatar3,
            name: "Dr. Lamar Davis",
            role: "COO at nSide, Inc.",
          },
        },
        {
          image: data.review4,
          author: {
            avatar: data.avatar4,
            name: "Rick Konop",
            role: "Route Operations Director at BE’s Coffee & Vending Services",
          },
        },
        {
          image: data.review5,
          author: {
            avatar: data.avatar5,
            name: "Bradley Kirshenbaum",
            role: "Director of Operations at Newcastle Limited",
          },
        },
      ],
      formSection: {
        preTitle: "REQUEST DEMO",
        title: "Interested in Rhombus?",
        description:
          "Get a demo today and see how Rhombus transforms IT departments into more nimble and scalable teams.",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Rhombus for Operations and Facilities Teams - Increase Operational
            Efficiency at Every Touchpoint
          </title>
          <meta
            name="description"
            content="Modernize your security operations with a unified platform that streamlines your tech stack and provides comprehensive, intelligent visibility."
          />
        </Helmet>
        <TeamsPageTemplate data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/teams/img/foot-traffic-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefitImage1: file(
        relativePath: { eq: "components/ITPage/img/modernize-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage2: file(
        relativePath: { eq: "components/teams/img/generic-alert-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage3: file(
        relativePath: { eq: "components/ITPage/img/partnership-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/teams/img/smart-search-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/teams/img/shared-clip-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/teams/img/user-roles-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/teams/img/in-depth-reporting-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/teams/img/integrations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar1: file(
        relativePath: { eq: "components/common/img/headshots/john-j.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar2: file(
        relativePath: { eq: "components/common/img/headshots/michael-s.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar3: file(
        relativePath: { eq: "components/common/img/headshots/lamar-d.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar4: file(
        relativePath: { eq: "components/common/img/headshots/rick-k.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar5: file(
        relativePath: { eq: "components/common/img/headshots/bradley-k.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      review1: file(
        relativePath: { eq: "components/common/img/reviews/john-j-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review2: file(
        relativePath: {
          eq: "components/common/img/reviews/michael-s-review.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review3: file(
        relativePath: { eq: "components/common/img/reviews/lamar-d-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review4: file(
        relativePath: { eq: "components/common/img/reviews/rick-k-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review5: file(
        relativePath: {
          eq: "components/common/img/reviews/bradley-k-review.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
